import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { PiEyeSlash, PiEyeLight } from "react-icons/pi";
import { BASE_URL } from "../data/data";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(BASE_URL + "admin/login", data);
      if (res.status === 201 || res.status === 200) {
        localStorage.setItem("userData", JSON.stringify(res.data.user));
        localStorage.setItem("token", JSON.stringify(res.data.token));
        navigate("/");
      } else {
        setLoading(false);
        enqueueSnackbar("Login Failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Login Failed", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const takeQuiz = () => {
    localStorage.setItem("userData", JSON.stringify({ name: "test" }));
    navigate("/take-quiz");
  };

  return (
    <div className="root-layout bg-[#fffffff] sm:min-h-[100vh] sm:min-w-[750px] flex flex-col w-full mt-5">
      <h1
        className="sm:ml-0 text-5xl font-extrabold mb-4 cursor-pointer"
        onClick={() => navigate("/")}
      >
        Skoolbod Quiz
      </h1>
      <div>
        <button
          onClick={takeQuiz}
          className="text-green-500 mt-2 mb-2 cursor-pointer"
        >
          Take Quiz With ID
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-4">
        <div>
          <SnackbarProvider />
        </div>
        {/* register your input into the hook by invoking the "register" function */}
        <label className=" text-[#3D3D3D] mb-2 flex">Email</label>
        <div className="flex border p-3 rounded-lg justify-between mb-5">
          <input
            className="flex flex-1 outline-none focus:outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
            type="email"
            {...register("email", { required: "Email Address is required" })}
            aria-invalid={errors.email ? "true" : "false"}
            placeholder="Enter your email"
          />
        </div>

        <label className=" text-[#3D3D3D] mb-2 flex">Password</label>
        <div className="flex border p-3 rounded-lg justify-between mb-5">
          <input
            className="flex flex-1 outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
            type={isPasswordVisible ? "text" : "password"}
            placeholder="Enter your password"
            {...register("password", { required: "Password is required" })}
            aria-invalid={errors.password ? "true" : "false"}
          />
          <p
            className="hover:cursor-pointer ml-2.5"
            onClick={handleTogglePasswordVisibility}
          >
            {isPasswordVisible ? <PiEyeSlash /> : <PiEyeLight />}
          </p>
        </div>
        {/* errors will return when field validation fails  */}
        {(errors.password || errors.email) && (
          <span className="text-red-600">Email and Password are required</span>
        )}

        <button
          disabled={loading}
          className={`p-2 mt-4 rounded-lg cursor-pointer text-white hover:bg-green-200 ${
            loading ? "bg-green-200 hover:cursor-wait" : "bg-green-500"
          }`}
        >
          {!loading ? "Login" : <span className="loader"></span>}
        </button>
      </form>
      <div className="mt-10 items-center justify-center flex">
        Dont have an account?{" "}
        <button
          className="text-green-500 ml-2"
          onClick={() => navigate("/sign-up")}
        >
          Sign up
        </button>
      </div>
    </div>
  );
};
export default Login;
