import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { PiEyeSlash, PiEyeLight } from "react-icons/pi";
import { BASE_URL } from "../data/data";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible((prev) => !prev);
  };

  const onSubmit = async (data) => {
    if (data.password !== data.cpassword) {
      enqueueSnackbar("Passwords Does Not Match!!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      try {
        setLoading(true);
        const res = await axios.post(BASE_URL + "admin/signup", data);
        if (res.status === 201 || res.status === 200) {
          enqueueSnackbar("Registered Successfully!!", {
            variant: "success",
            autoHideDuration: 3000,
          });
          navigate("/login");
        } else {
          setLoading(false);
          enqueueSnackbar("Signup Failed!!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        if (error.name === "AxiosError") {
          const message = error.response.data;
          setLoading(false);
          enqueueSnackbar(`Sign-up failed: ${message.error}`, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          setLoading(false);
          enqueueSnackbar("Signup Failed!!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    }
  };

  return (
    <div className="root-layout bg-[#fffffff] sm:min-h-[100vh] sm:min-w-[750px] flex flex-col w-full mt-5">
      <h1
        className="sm:ml-0 max-md:text-xl text-5xl font-extrabold mb-4 cursor-pointer"
        onClick={() => navigate("/")}
      >
        Skoolbod Quiz
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <div>
          <SnackbarProvider />
        </div>
        {/* register your input into the hook by invoking the "register" function */}
        <label>Name</label>
        <div className="flex border p-3 rounded-lg justify-between mb-5">
          <input
            className="flex flex-1 outline-none focus:outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
            placeholder="Enter your name"
            {...register("username", { required: "Name is required" })}
            aria-invalid={errors.username ? "true" : "false"}
          />
        </div>
        <label>Email</label>
        <div className="flex border p-3 rounded-lg justify-between mb-5">
          <input
            className="flex flex-1 outline-none focus:outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
            type="email"
            placeholder="Enter your email address"
            {...register("email", { required: "Email Address is required" })}
            aria-invalid={errors.email ? "true" : "false"}
          />
        </div>

        <label>Password</label>
        <div className="flex border p-3 rounded-lg justify-between mb-5">
          <input
            className="flex flex-1 outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
            type={isPasswordVisible ? "text" : "password"}
            placeholder="Enter your password"
            {...register("password", { required: "Password is required" })}
            aria-invalid={errors.password ? "true" : "false"}
          />
          <p
            className="hover:cursor-pointer ml-2.5"
            onClick={handleTogglePasswordVisibility}
          >
            {isPasswordVisible ? <PiEyeSlash /> : <PiEyeLight />}
          </p>
        </div>

        <label>Confirm Password</label>
        <div className="flex border p-3 rounded-lg justify-between mb-5">
          <input
            className="flex flex-1 outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
            type={isConfirmPasswordVisible ? "text" : "password"}
            placeholder="Enter your password"
            {...register("cpassword", { required: "Password is required" })}
            aria-invalid={errors.cpassword ? "true" : "false"}
          />
          <p
            className="hover:cursor-pointer ml-2.5"
            onClick={handleToggleConfirmPasswordVisibility}
          >
            {isConfirmPasswordVisible ? <PiEyeSlash /> : <PiEyeLight />}
          </p>
        </div>
        {/* errors will return when field validation fails  */}
        {(errors.password ||
          errors.email ||
          errors.username ||
          errors.cpassword) && (
          <span className="text-red-600">All fields are required!!</span>
        )}

        <button
          disabled={loading}
          className={`p-2 mt-4 rounded-sm cursor-pointer text-white hover:bg-green-200 ${
            loading ? "bg-green-200 hover:cursor-wait" : "bg-green-500"
          }`}
        >
          {!loading ? "Register" : <span className="loader"></span>}
        </button>
      </form>
      <div className="mt-10 items-center justify-center flex">
        Already have an account?
        <button
          className="text-green-500 ml-2"
          onClick={() => navigate("/login")}
        >
          Sign in
        </button>
      </div>
    </div>
  );
};
export default SignUp;
