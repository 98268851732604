import { useNavigate } from "react-router-dom";
import { useQuizStore } from "../components/store";
import Timer from "./Timer";
import { useState, useEffect } from "react";

const StudentQuiz = () => {
  const { data } = useQuizStore();
  const navigate = useNavigate();
  const [startQuiz, setStartQuiz] = useState(false);
  const [timeOver, setTimeOver] = useState(false);
  const [timeDiff, setTimeDiff] = useState(0);
  const [quizTimeRemaining, setQuizTimeRemaining] = useState(
    Number(data?.allowedTime) + 0.5
  );

  useEffect(() => {
    if (data && data.quizDate) {
      const diff =
        (new Date(data.quizDate).getTime() - new Date().getTime()) /
        (1000 * 60);
      setTimeDiff(diff);
      if (diff < 0) {
        const quizTimeRemaining = new Date(
          new Date(data.quizDate).getTime() + Number(data.allowedTime) * 60000
        );
        const diffWithtime =
          (new Date(quizTimeRemaining).getTime() - new Date().getTime()) /
          (1000 * 60);
        if (diffWithtime <= 2) {
          setTimeOver(true);
          setStartQuiz(false);
        } else {
          setQuizTimeRemaining(diffWithtime + 0.5);
          setStartQuiz(true);
        }
      } else if (diff === 0) {
        setStartQuiz(true);
      }
    } else {
      navigate("/take-quiz");
    }
  }, [data, navigate]);

  useEffect(() => {
    if (startQuiz) {
      navigate("/quiz");
    }
  }, [startQuiz, navigate]);

  return (
    <div style={{ textAlign: "center" }}>
      {timeDiff > 0 && (
        <Timer
          time={timeDiff}
          title={"Time left for quiz to start"}
          setShowResult={setStartQuiz}
        />
      )}
      {timeOver && <div>Quiz time has elapsed</div>}
      {startQuiz && <div>Time reamining to take quiz: {quizTimeRemaining}</div>}
    </div>
  );
};
export default StudentQuiz;
