import { useState } from "react";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../data/data";
import { useQuizStore } from "../components/store";

const TakeQuiz = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setData } = useQuizStore();

  const onSubmit = async () => {
    try {
      if (!id || !name) {
        enqueueSnackbar("Name and Exam ID required", {
          variant: "error",
          autoHideDuration: 3000,
        });
      } else {
        setLoading(true);
        const res = await axios.get(`${BASE_URL}quiz/${id}`);
        if (res.status === 200) {
          setLoading(false);
          setData({ ...res.data, name });
          navigate("/take-quiz/quiz");
        } else {
          setLoading(false);
          enqueueSnackbar("Error fetching Quiz", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar("Error fetching Quiz", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="flex flex-col w-full min-h-[80vh]">
      <SnackbarProvider />
      <div>
        <h1 className="text-red-600 text-xl font-bold">
          Students are not required to login
        </h1>
      </div>
      <label className="mt-5">Name</label>
      <div className="flex border p-3 rounded-lg justify-between mb-5">
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Enter your name"
          className="flex flex-1 outline-none focus:outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
        />
      </div>

      <label className="mt-3">Exam ID</label>
      <div className="flex border p-3 rounded-lg justify-between mb-5">
        <input
          value={id}
          onChange={(e) => setId(e.target.value)}
          type="text"
          placeholder="Enter your exam ID"
          className="flex flex-1 outline-none focus:outline-none text-sm text-[#1F1F1F] bg-inherit placeholder:text-[#A3A3A3] placeholder:font-medium"
        />
      </div>
      <button
        disabled={loading}
        onClick={onSubmit}
        className={`p-2 mt-4 rounded-sm cursor-pointer text-white  hover:bg-green-200 ${
          loading ? "bg-green-200" : "bg-green-500"
        }`}
      >
        {loading ? "Loading Quiz" : "Take Quiz"}
      </button>
    </div>
  );
};
export default TakeQuiz;
